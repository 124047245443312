import { PersonOfInterestType } from "../../types";
import i18n from "../../../../i18n";
import moment from "moment";
//import { getDuplicateUbos } from "../../service";
export function validateUboData(
  data: PersonOfInterestType,
  allPersons: PersonOfInterestType[]
) {
  if (data) {
    if (
      !data.firstName ||
      !data.lastName ||
      !data.placeOfBirth ||
      !data.dob ||
      !data.nationality?.length ||
      !data.taxIdNumber ||
      !data.street ||
      !data.houseNo ||
      !data.zip ||
      !data.city ||
      !data.country ||
      !data.justification
    ) {
      return i18n.t("Please enter valid values in all mandatory fields");
    }
    // Validation patterns
    const specialCharsRegex = /[;'"\\&|<>\[\]{}\(\)$!@]/;

    const fieldsToValidate = [
      data.firstName,
      data.lastName,
      data.placeOfBirth,
      data.city,
      data.street,
      data.houseNo,
      data.zip,
      data.justification,
      data.taxIdNumber
    ];

    for (const field of fieldsToValidate) {
      if (specialCharsRegex.test(field)) {
        return i18n.t(
          "Special characters are not allowed. Please remove in order to proceed."
        );
      }
    }

    if (data.dob) {
      const inputDate = moment(data.dob, "DD-MM-YYYY"); // Parse the input date
      const fiveYearsAgo = moment().subtract(5, "years");

      if (inputDate.isAfter(fiveYearsAgo)) {
        return i18n.t("Date of birth should be at least 5 years old");
      }
    }
    // let duplicates = getDuplicateUbos(allPersons.concat([data]));
    // if (duplicates.length) {
    //   return `Following ubos are duplicate - ${duplicates.toString()}`;
    // }
  }
  return "";
}
export function trimAllVals(data: PersonOfInterestType) {
  return {
    ...data,
    firstName: data.firstName?.trim(),
    lastName: data.lastName?.trim(),
    justification: data.justification?.trim()
  };
}
