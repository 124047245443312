import React, { useState, useEffect } from "react";
import InputField from "../../../presentation/InputField";
import { PersonOfInterestType } from "../../types";
import FieldErrorMessage from "../../../presentation/FieldErrorMessage";
import MandatoryIcon from "../../../presentation/MandatoryIcon";
import { validateUboData, trimAllVals } from "./service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getDate, modifyForSelectBoxi18n } from "../../../../lib/utils";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import {
  getMultiSelectValue,
  getSelectDefaultValue
} from "../../AddUBOs/service";
import { appData } from "../../../../services/appConfig";
import { RiskCountry } from "../../../../types/applicationData";
import i18n from "../../../../i18n";
interface Props {
  selectedPersonOfInterest?: PersonOfInterestType;
  onSave: (data: PersonOfInterestType) => void;
  allowEdit: boolean;
  allPersons: PersonOfInterestType[];
  questionId: string | number;
}

const AddPersonOfInterest: React.FC<Props> = ({
  selectedPersonOfInterest,
  onSave,
  allowEdit,
  allPersons,
  questionId
}) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState<RiskCountry[]>([]);
  const [personOfInterest, setPersonOfInterest] = useState<
    PersonOfInterestType | undefined
  >();
  const [showValidationError, setShowValidationError] = useState(false);
  useEffect(() => {
    appData.fetchRiskCountries().then((res: any) => {
      setCountries(res);
    });
  }, []);
  useEffect(() => {
    selectedPersonOfInterest && setPersonOfInterest(selectedPersonOfInterest);
    setShowValidationError(false);
  }, [selectedPersonOfInterest]);
  function onInputChange({ target }: { target: any }) {
    setPersonOfInterest({ ...personOfInterest!, [target.name]: target.value });
    setShowValidationError(false);
  }
  function savePersonOfInterest() {
    if (allowEdit && personOfInterest) {
      let parsedData = trimAllVals(personOfInterest);
      setShowValidationError(true);
      let validationMessage = validateUboData(parsedData, allPersons || []);
      if (validationMessage) {
        toast.error(t(validationMessage));
      } else {
        parsedData && onSave(parsedData);
        setShowValidationError(false);
        setPersonOfInterest(undefined);
      }
    }
  }
  return (
    <div>
      <div className="company-ubo-heading">
        {" "}
        {t("Add UBO that excercises control over client")}
      </div>
      {allowEdit && (
        <>
          <div className="company-ubo-deas">
            {t(
              "Examples: Veto-rights regarding decisions of member, general or shareholder meetings or general partners of a limited partnership or a limited liability company & limited partnership."
            )}
          </div>
          <div className="add-person-form">
            <div className="form">
              <div className="add-person-form-in">
                <div className="ubo-add-input">
                  <div className="form-group common-input">
                    <label htmlFor="text" className="label-pd">
                      <MandatoryIcon /> {t("First Name")}
                    </label>
                    <InputField
                      inputType="text"
                      id={`${questionId}-firstName`}
                      placeholder="First name"
                      value={personOfInterest?.firstName || ""}
                      name="firstName"
                      onChange={onInputChange}
                      isDisabled={!allowEdit}
                    />
                    {showValidationError && !personOfInterest?.firstName && (
                      <div>
                        <FieldErrorMessage errorMessage="Please enter first name" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="ubo-add-input">
                  <div className="form-group common-input">
                    <label htmlFor="text" className="label-pd">
                      <MandatoryIcon /> {t("Last Name")}
                    </label>
                    <InputField
                      inputType="text"
                      id={`${questionId}-lastName`}
                      placeholder="Last name"
                      value={personOfInterest?.lastName || ""}
                      name="lastName"
                      onChange={onInputChange}
                      isDisabled={!allowEdit}
                    />
                    {showValidationError && !personOfInterest?.lastName && (
                      <div>
                        <FieldErrorMessage errorMessage="Please enter last name" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="ubo-add-input">
                  <div className="form-group common-input">
                    <label htmlFor="text" className="label-pd">
                      <MandatoryIcon /> {t("Justification")}
                    </label>
                    <textarea
                      className="form-control"
                      id={`${questionId}-justification`}
                      placeholder={t("Justification")}
                      value={personOfInterest?.justification || ""}
                      name="justification"
                      onChange={onInputChange}
                      disabled={!allowEdit}
                    ></textarea>
                    {showValidationError &&
                      !personOfInterest?.justification && (
                        <div>
                          <FieldErrorMessage errorMessage="Please enter justification" />
                        </div>
                      )}
                  </div>
                </div>
                <div className="ubo-add-input">
                  <div className="form-group common-input">
                    <label htmlFor="placeOfBirth" className="label-pd">
                      <MandatoryIcon /> {t("Place of birth")}
                    </label>
                    <InputField
                      inputType="text"
                      id={`${questionId}-placeOfBirth`}
                      placeholder="Place of birth"
                      value={personOfInterest?.placeOfBirth || ""}
                      name="placeOfBirth"
                      onChange={onInputChange}
                      isDisabled={!allowEdit}
                    />
                    {showValidationError && !personOfInterest?.placeOfBirth && (
                      <div>
                        <FieldErrorMessage errorMessage="Please enter place of birth" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="ubo-add-input">
                  <div className="form-group common-input">
                    <label htmlFor="text" className="label-pd">
                      <MandatoryIcon /> {t("Date of birth")}
                    </label>
                    <Flatpickr
                      disabled={!allowEdit}
                      value={personOfInterest?.dob}
                      onChange={(date: any) => {
                        let dateStr = getDate(date[0]);
                        onInputChange({
                          target: { name: "dob", value: dateStr }
                        });
                      }}
                      className="form-control form-control--bordered"
                      options={{
                        dateFormat: "d-m-Y",
                        mode: "single",
                        disable: [
                          function (date) {
                            return date > new Date();
                          }
                        ]
                      }}
                    />
                    {showValidationError && !personOfInterest?.dob && (
                      <div>
                        <FieldErrorMessage errorMessage="Please enter date of birth" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="ubo-add-input">
                  <div className="form-group common-input">
                    <label htmlFor="text" className="label-pd">
                      <MandatoryIcon /> {t("Nationalities")}
                    </label>
                    <Select
                      value={
                        personOfInterest?.nationality
                          ? getMultiSelectValue(
                              personOfInterest?.nationality,
                              countries
                            )
                          : []
                      }
                      // value={ubo?.nationality || []}
                      options={modifyForSelectBoxi18n(
                        countries,
                        "id",
                        "name",
                        i18n.language === "de" ? "germanName" : "name"
                      )}
                      isMulti={true}
                      // name="country"
                      placeholder={t("Nationalities")}
                      onChange={(ele: any) => {
                        onInputChange({
                          target: {
                            name: "nationality",
                            value: ele
                          }
                        });
                      }}
                      isDisabled={!allowEdit}
                    />
                    {showValidationError && !personOfInterest?.nationality && (
                      <div>
                        <FieldErrorMessage errorMessage="Please enter nationalities" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="ubo-add-input">
                  <div className="form-group common-input">
                    <label htmlFor="text" className="label-pd">
                      <MandatoryIcon /> {t("Country")}
                    </label>
                    <Select
                      value={
                        personOfInterest?.country
                          ? getSelectDefaultValue(
                              personOfInterest?.country.id,
                              countries
                            )
                          : null
                      }
                      options={modifyForSelectBoxi18n(
                        countries,
                        "id",
                        "name",
                        i18n.language === "de" ? "germanName" : "name"
                      )}
                      // name="country"
                      placeholder={t("Country")}
                      onChange={(ele: any) => {
                        onInputChange({
                          target: {
                            name: "country",
                            value: { id: ele.id, name: t(ele.value) }
                          }
                        });
                      }}
                      isDisabled={!allowEdit}
                      noOptionsMessage={() => t("No options")}
                    />
                    {showValidationError && !personOfInterest?.country && (
                      <div>
                        <FieldErrorMessage errorMessage="Please enter country" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="ubo-add-input">
                  <div className="form-group common-input">
                    <label htmlFor="text" className="label-pd">
                      <MandatoryIcon /> {t("Tax-ID")}
                    </label>
                    <InputField
                      inputType="text"
                      id={`${questionId}-tax-id-number`}
                      placeholder="xxx-xx-xx"
                      value={personOfInterest?.taxIdNumber || ""}
                      name="taxIdNumber"
                      onChange={onInputChange}
                      isDisabled={!allowEdit}
                    />
                    {showValidationError && !personOfInterest?.taxIdNumber && (
                      <div>
                        <FieldErrorMessage errorMessage="Please enter tax-id" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="ubo-add-input">
                  <div className="form-group common-input">
                    <label htmlFor="text" className="label-pd">
                      <MandatoryIcon /> {t("Street")}
                    </label>
                    <InputField
                      inputType="text"
                      id={`${questionId}-street`}
                      placeholder="Street"
                      value={personOfInterest?.street || ""}
                      name="street"
                      onChange={onInputChange}
                      isDisabled={!allowEdit}
                    />
                    {showValidationError && !personOfInterest?.street && (
                      <div>
                        <FieldErrorMessage errorMessage="Please enter street" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="ubo-add-input">
                  <div className="form-group common-input">
                    <label htmlFor="text" className="label-pd">
                      <MandatoryIcon /> {t("House no.")}
                    </label>
                    <InputField
                      inputType="text"
                      id={`${questionId}-house-no`}
                      placeholder="House no."
                      value={personOfInterest?.houseNo || ""}
                      name="houseNo"
                      onChange={onInputChange}
                      isDisabled={!allowEdit}
                    />
                    {showValidationError && !personOfInterest?.houseNo && (
                      <div>
                        <FieldErrorMessage errorMessage="Please enter House no." />
                      </div>
                    )}
                  </div>
                </div>
                <div className="ubo-add-input">
                  <div className="form-group common-input">
                    <label htmlFor="text" className="label-pd">
                      <MandatoryIcon /> {t("Zip")}
                    </label>
                    <InputField
                      inputType="text"
                      id={`${questionId}-zip`}
                      placeholder="Zip"
                      value={personOfInterest?.zip || ""}
                      name="zip"
                      onChange={onInputChange}
                      isDisabled={!allowEdit}
                    />
                    {showValidationError && !personOfInterest?.zip && (
                      <div>
                        <FieldErrorMessage errorMessage="Please enter zip" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="ubo-add-input">
                  <div className="form-group common-input">
                    <label htmlFor="text" className="label-pd">
                      <MandatoryIcon /> {t("City")}
                    </label>
                    <InputField
                      inputType="text"
                      id={`${questionId}-city`}
                      placeholder="City"
                      value={personOfInterest?.city || ""}
                      name="city"
                      onChange={onInputChange}
                      isDisabled={!allowEdit}
                    />
                    {showValidationError && !personOfInterest?.city && (
                      <div>
                        <FieldErrorMessage errorMessage="Please enter city" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="last-action-ubo">
                <button
                  onClick={savePersonOfInterest}
                  disabled={!allowEdit}
                  className="btn btn-primary"
                >
                  {t("Save")}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AddPersonOfInterest;
