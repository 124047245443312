import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext
} from "react";
import Icons from "../Icons";
import "./styles.scss";
import { UploadedFiles } from "../../container/QuestionComponent/types";
import {
  downloadFile,
  deleteFile,
  uploadFile
} from "../../../services/FileUploadDownloadService";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import ConfirmationModal from "../Modals/ConfirmationModal";
import { getAppLoader as Load } from "../Loader/services";
import { useTranslation } from "react-i18next";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ImportInfo from "../../container/ImportInfo";
import * as apis from "../../../apis/CompanySearch";
import { hasKyckrPermission } from "../../../lib/questionnairePermissionsAccess";
import { QuestionnaireContext } from "../../../Contexts/QuestionnaireContext";

type UploadedFilesResponse = {
  originalFileName: string;
  timeStampedFileName: string;
};
interface Props {
  uploadFiles?: boolean;
  downloadFiles?: boolean;
  files?: UploadedFiles[];
  onChange?: (
    files: [UploadedFilesResponse],
    datasetQuestionAnswerId?: string | number
  ) => void;
  fileParams?: any;
  uploaderId: string;
  uploadButtonName?: string;
  isCompanySearchQuestion?: string;
  isCompanySearchQuestionParent?: boolean;
  searchedCompanyDetails?: any;
  externalDocumentOrders?: any;
}
const Files: React.FC<Props> = ({
  uploadFiles,
  downloadFiles,
  files,
  onChange,
  fileParams,
  uploaderId,
  uploadButtonName = "Upload multiple files",
  isCompanySearchQuestion,
  isCompanySearchQuestionParent = false,
  searchedCompanyDetails,
  externalDocumentOrders
}) => {
  const { t } = useTranslation();
  const [filesUploadingCount, setFilesUploadingCount] = useState<number>(0);
  const [filesList, setFilesList] = useState<UploadedFiles[]>(files || []);
  const filesRef = useRef(filesList);
  filesRef.current = filesList;
  const filesUploadingCountRef = useRef(filesUploadingCount);
  filesUploadingCountRef.current = filesUploadingCount;
  const [erroredFileList, setErroredFileList] = useState<any[]>([]);
  const [confirmationBoxData, setConfirmationBoxData] = useState({
    title: "",
    message: "",
    callBack: () => {}
  });
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const fileInputElem = "cddFileUploader-" + uploaderId;
  const [showImportInfo, setShowImportInfo] = useState(false);
  const [externalDocumentOrdersList, setExternalDocumentOrdersList] = useState<
    any[]
  >(externalDocumentOrders || []);
  const [importedDocOrderQasId, setImportedDocOrderQasId] = useState<
    string | number
  >();
  const { questionnaire } = useContext(QuestionnaireContext);

  const openUploaderBox = (event: any) => {
    event.stopPropagation();
    const element = document.getElementById(fileInputElem) as HTMLElement;
    element && element.click();
  };

  const loadFiles = (event: any) => {
    event.stopPropagation();
    Load.disableLoader();
    let files = event.target.files;
    let tempArr = [];
    setFilesUploadingCount(files.length);
    for (let k = 0; k < files.length; k++) {
      let randomNum = Math.floor(
        Math.random() * 100 + new Date().getTime()
      ).toString();
      let tempId = randomNum;
      tempArr.push({
        tempFileId: tempId,
        loading: true,
        originalFileName: files[k].name
      });

      uploadFile(files[k], {
        ...fileParams,
        tempFileId: randomNum
      })
        .then((res: AxiosResponse) => {
          let data = res?.data.data;
          let updatedFilesList: any = addToFilesList(data);

          setFilesUploadingCount(filesUploadingCountRef.current - 1);
          if (filesUploadingCountRef.current === 0) {
            Load.enableLoader();
          }
          const combinedItems: any = [
            ...updatedFilesList?.map((val: any) => {
              return {
                originalFileName: val.originalFileName,
                timeStampedFileName: val.timeStampedFileName,
                tempFileId: val.tempFileId,
                loading: val?.loading
              };
            }),
            ...externalDocumentOrdersList
          ];
          onChange && onChange(combinedItems);
        })
        .catch((err: Error) => {
          Load.enableLoader();
          removeFromLocalFilesList(tempId);
          let updatedFilesList: any = removeFromLocalFilesList(tempId);
          onChange &&
            onChange(
              updatedFilesList?.map((val: any) => {
                return {
                  originalFileName: val.originalFileName,
                  timeStampedFileName: val.timeStampedFileName,
                  tempFileId: val.tempFileId,
                  loading: val?.loading
                };
              })
            );
          toast.error(err.message);
        });
    }
    if (tempArr.length) {
      setFilesList([...filesList, ...tempArr]);
    }
  };

  const addToFilesList = (data: any) => {
    const newFilesList = filesRef.current.map((file: any) => {
      if (file.tempFileId === data.tempFileId) {
        const updatedFile = {
          ...file,
          loading: false,
          //tempFileId: null,
          timeStampedFileName: data.timeStampedFileName
        };
        return updatedFile;
      }
      return file;
    });

    setFilesList(newFilesList);
    return newFilesList;
  };

  const removeFromFilesList = (timeStampedFileName: string) => {
    let tempArr = filesRef?.current?.length ? [...filesRef.current] : [];
    let removeIndex = filesRef.current
      .map((file: any) => {
        return file.timeStampedFileName;
      })
      .indexOf(timeStampedFileName);

    if (removeIndex > -1 && tempArr.length) {
      tempArr.splice(removeIndex, 1);
      setFilesList(tempArr);
    }
    return tempArr;
  };

  const removeFromLocalFilesList = (tempFileId: string) => {
    let tempArr: UploadedFiles[] = filesRef?.current?.length
      ? [...filesRef.current]
      : [];
    let removeIndex = filesRef.current
      .map((file: any) => {
        return file.tempFileId;
      })
      .indexOf(tempFileId);
    let erroredFile = tempArr[removeIndex];
    erroredFile &&
      setErroredFileList((prev: any) => {
        return [...prev, erroredFile];
      });
    if (removeIndex > -1 && tempArr.length) {
      tempArr.splice(removeIndex, 1);
      setFilesList(tempArr);
    }
    return tempArr;
  };

  const onDelete = (
    timeStampedFileName: string,
    id: string | number | null
  ) => {
    setConfirmationBoxData(
      Object.assign(
        {},
        {
          title: "Confirm Delete",
          message: "Are you sure want to delete this file?",
          callBack: () => {
            setShowConfirmationModal(false);
            deleteFile({
              accountLevelOneQuestionnaireId:
                fileParams.accountLevelOneQuestionnaireId,
              timeStampedFileName,
              fileType: fileParams.fileType,
              questionnaireFileUploadId: id,
              sectionId: fileParams.sectionId
            })
              .then((res: any) => {
                let updatedFilesList: any =
                  removeFromFilesList(timeStampedFileName);
                toast.success(res?.message || t("File deleted successfully"));
                onChange &&
                  onChange(
                    updatedFilesList?.map((val: any) => {
                      return {
                        originalFileName: val.originalFileName,
                        timeStampedFileName: val.timeStampedFileName
                      };
                    })
                  );
              })
              .catch((err: Error) => {
                toast.error(err.message);
              });
          }
        }
      )
    );
    setShowConfirmationModal(true);
  };

  const removeFromExternalOrderList = (id: string | number) => {
    let tempArr = [...externalDocumentOrdersList];
    const files = filesRef?.current?.length ? [...filesRef.current] : [];
    const fileIndex = files.findIndex(
      (file: any) => file.externalOrderId === id
    );
    let removeIndex = externalDocumentOrdersList
      .map((file: any) => {
        return file.id;
      })
      .indexOf(id);
    if (removeIndex > -1 && tempArr.length) {
      tempArr.splice(removeIndex, 1);
      setExternalDocumentOrdersList(tempArr);
    }
    if (fileIndex > -1 && files.length) {
      files.splice(fileIndex, 1);
      setFilesList(files);
    }
  };

  const onExternalDocumentDelete = (title: string, id: string | number) => {
    setConfirmationBoxData(
      Object.assign(
        {},
        {
          title: t("Confirm Deletion"),
          message: t(
            "Are you sure you want to delete the document? Once the document is deleted, it is unavaible and you will have to request it again, for which additional costs will occur."
          ),
          callBack: () => {
            setShowConfirmationModal(false);
            apis
              .deleteExternalOrder({
                accountQuestionnaireId:
                  fileParams.accountLevelOneQuestionnaireId,
                questionAnswerId:
                  fileParams.questionAnswerId || importedDocOrderQasId,
                sectionId: fileParams.sectionId,
                externalOrderId: id
              })
              .then((res: any) => {
                removeFromExternalOrderList(id);
                toast.success(res.data?.deleteExternalOrder?.message);
              })
              .catch((err: Error) => {
                toast.error(err.message);
              });
          }
        }
      )
    );
    setShowConfirmationModal(true);
  };

  const onDownload = (
    timeStampedFileName: string,
    originalFileName: string
  ) => {
    downloadFile({
      accountLevelOneQuestionnaireId: fileParams.accountLevelOneQuestionnaireId,
      timeStampedFileName,
      originalFileName,
      fileType: fileParams.fileType,
      sectionId: fileParams.sectionId
    });
  };
  const onImportInfoClick = () => {
    setShowImportInfo(true);
  };

  const addDocumentsToFiles = (
    documents: any,
    datasetQuestionAnswerId?: string | number,
    questionAnswerId?: string | number
  ) => {
    const updatedList = [...externalDocumentOrdersList];

    documents.map((document: any) => {
      const index = updatedList.findIndex(
        (externalDocument: any) => externalDocument.id === document.id
      );

      if (index !== -1) {
        updatedList[index].status = document.status;
        return;
      }
      updatedList.push(document);
      return documents;
    });
    setExternalDocumentOrdersList(updatedList);
    const combinedItems: any = [...filesList, ...documents];
    setImportedDocOrderQasId(questionAnswerId);
    if (fileParams.datasetQuestionAnswerId) {
      onChange && onChange(combinedItems, datasetQuestionAnswerId);
    }
  };

  useEffect(() => {
    let tempFiles = [];
    if (files?.length) {
      tempFiles = files.map((file: any) => {
        return {
          tempFileId: null,
          ...file
        };
      });
    }
    setFilesList(tempFiles);
  }, [files]);

  function getFileUploaderType(): any {
    return (
      <>
        {uploadFiles && (
          <>
            <input
              id={fileInputElem}
              type="file"
              accept=".pdf, .xls, .xlsx, .doc, .docx, .odt, .ods, .ppt, .pptx, .png, .jpg, .jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
              className="file-uploader__input"
              onChange={(event: any) => {
                loadFiles(event);
                event.target.value = null;
              }}
              multiple
            />
            <div className="button-container">
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-outline-primary mb-1"
                  onClick={openUploaderBox}
                >
                  {t(uploadButtonName)}
                  <Icons className="icon--x-small ml-1" icon="upload" />
                </button>
                {isCompanySearchQuestion &&
                  hasKyckrPermission({
                    permissions:
                      questionnaire?.sectionDetail?.permissions || [],
                    permissionType: "Documents"
                  }) && (
                    <button
                      className="btn btn-outline-primary mb-1 ml-2"
                      onClick={onImportInfoClick}
                      disabled={!questionnaire?.allowExternalInfoRetrieval}
                    >
                      {t("Import Documents")}
                    </button>
                  )}
              </div>
              <div>
                (
                {t(
                  "Eligible file extensions: .pdf, .docx, .doc, .xls, .xlsx, .odt, .ods, .ppt, .pptx, .jpeg, .png, .jpg."
                )}
                )
              </div>
            </div>
            {/* Modal for confirmation box start */}
            <ConfirmationModal
              modalTitle={confirmationBoxData.title}
              message={confirmationBoxData.message}
              callBack={confirmationBoxData.callBack}
              showConfirmationModal={showConfirmationModal}
              onModalClose={() => {
                setShowConfirmationModal(false);
              }}
            />
            {showImportInfo && (
              <ImportInfo
                isCompanySearchQuestionParent={isCompanySearchQuestionParent}
                isCompanySearchQuestion={isCompanySearchQuestion}
                searchedCompanyDetails={searchedCompanyDetails}
                fileParams={fileParams}
                hide={() => {
                  setShowImportInfo(false);
                }}
                addDocumentsToFiles={addDocumentsToFiles}
                datasetQuestionAnswer={fileParams.datasetQuestionAnswer}
                datasetQuestionAnswerId={fileParams.datasetQuestionAnswerId}
              />
            )}
          </>
        )}
        <div className="file-upload-here">
          <div className="action-group">
            {downloadFiles && filesRef.current && !!filesRef.current.length
              ? filesRef.current.map((file, index) => {
                  return (
                    <OverlayTrigger
                      placement="bottom"
                      key={index}
                      overlay={
                        <Tooltip id={`tooltip-${index}`}>
                          <span>{file.originalFileName}</span>
                        </Tooltip>
                      }
                    >
                      <div
                        className="action-group-in align-items-center"
                        key={index}
                      >
                        {uploadFiles && file.loading && (
                          <div className="action action--view">
                            <Icons className="icon" icon="saving" />
                          </div>
                        )}
                        <div className="action action--view mr-1 align-items-center flex-column">
                          <span className="fixed-filename">
                            {file.originalFileName}
                          </span>
                          <span className="align-self-start">
                            ({" "}
                            {file.externalOrderId
                              ? t("Imported from Registry")
                              : t("Uploaded by User")}{" "}
                            )
                          </span>
                        </div>

                        {file.timeStampedFileName && (
                          <>
                            {" "}
                            <div
                              className="action action--view"
                              onClick={() => {
                                if (file.loading) return false;
                                onDownload(
                                  file.timeStampedFileName as string,
                                  file.originalFileName as string
                                );
                              }}
                            >
                              <Icons className="icon" icon="download" />
                            </div>
                            {uploadFiles &&
                              (!file.externalOrderId ||
                                hasKyckrPermission({
                                  permissions:
                                    questionnaire?.sectionDetail?.permissions ||
                                    [],
                                  permissionType: "DeleteDocuments"
                                })) && (
                                <div
                                  className="action action--delete"
                                  onClick={() => {
                                    if (file.loading) return false;
                                    if (file.externalOrderId) {
                                      onExternalDocumentDelete(
                                        file.originalFileName as string,
                                        file.externalOrderId
                                      );
                                      return false;
                                    }
                                    onDelete(
                                      file.timeStampedFileName as string,
                                      file.id || null
                                    );
                                  }}
                                >
                                  <Icons className="icon" icon="delete" />
                                </div>
                              )}
                          </>
                        )}
                      </div>
                    </OverlayTrigger>
                    // <div className="action-group" key={index}>
                    //   {uploadFiles && file.loading && (
                    //     <div className="action action--view">
                    //       <Icons className="icon" icon="saving" />
                    //     </div>
                    //   )}

                    //   <div
                    //     className="action action--view"
                    //     onClick={() => {
                    //       if (file.loading) return false;
                    //       onDownload(
                    //         file.timeStampedFileName as string,
                    //         file.originalFileName as string
                    //       );
                    //     }}
                    //   >
                    //     <Icons className="icon" icon="download" />
                    //   </div>

                    //   {uploadFiles && (
                    //     <div
                    //       className="action action--delete"
                    //       onClick={() => {
                    //         if (file.loading) return false;
                    //         onDelete(
                    //           file.timeStampedFileName as string,
                    //           file.id || null
                    //         );
                    //       }}
                    //     >
                    //       <Icons className="icon" icon="delete" />
                    //     </div>
                    //   )}
                    //   <div className="action action--view mr-1 align-items-center">
                    //     <span className="fixed-filename">
                    //       {file.originalFileName}
                    //     </span>
                    //   </div>
                    // </div>
                  );
                })
              : externalDocumentOrdersList?.length
              ? ""
              : t("No files uploaded")}
            {!!externalDocumentOrdersList?.length &&
              externalDocumentOrdersList.map((file: any, index: any) => {
                return (
                  <OverlayTrigger
                    placement="bottom"
                    key={index}
                    overlay={
                      file.status === "pending" ? (
                        <Tooltip id={`tooltip-${index}`}>
                          <span>
                            {t("The document is currently being retrieved.")}
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip id={`tooltip-${index}`}>
                          <span>
                            {t(file.message || "Error while uploading")}
                          </span>
                        </Tooltip>
                      )
                    }
                  >
                    <div
                      className="action-group-in align-items-center"
                      key={index}
                    >
                      {file.status === "pending" ? (
                        <div className="action action--view">
                          <Icons className="icon" icon="pending" />
                        </div>
                      ) : (
                        <div className="action action--view">
                          <Icons className="icon" icon="error" />
                        </div>
                      )}
                      <div className="action action--view mr-1 align-items-center flex-column">
                        <span className="fixed-filename">{file.title}</span>
                        <span className="align-self-start">
                          ( {t("Imported from Registry")} )
                        </span>
                      </div>

                      {file.title && (
                        <>
                          {" "}
                          {uploadFiles &&
                            hasKyckrPermission({
                              permissions:
                                questionnaire?.sectionDetail?.permissions || [],
                              permissionType: "DeleteDocuments"
                            }) && (
                              <div
                                className="action action--delete"
                                onClick={() => {
                                  onExternalDocumentDelete(
                                    file.title as string,
                                    file.id
                                  );
                                }}
                              >
                                <Icons className="icon" icon="delete" />
                              </div>
                            )}
                        </>
                      )}
                    </div>
                  </OverlayTrigger>
                );
              })}
            {!!erroredFileList.length &&
              erroredFileList.map((file, index) => {
                return (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-${index}`}>
                        <span>
                          Error while uploading {file?.originalFileName}, Please
                          try again.
                        </span>
                      </Tooltip>
                    }
                  >
                    <div
                      className="action-group-in align-items-center"
                      key={index}
                    >
                      <div className="action action--view mr-1 align-items-center flex-column">
                        <span className="fixed-filename">
                          {file?.originalFileName}
                        </span>
                        <span className="align-self-start">
                          ( {t("Imported from Registry")} )
                        </span>
                      </div>
                      <div className="action action--view">
                        <Icons className="icon" icon="error" />
                      </div>
                    </div>
                  </OverlayTrigger>
                );
              })}
          </div>
        </div>
      </>
    );
  }
  return getFileUploaderType();
};

export default Files;
